import {CanActivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

export const authGuard: CanActivateFn = (): Observable<boolean> => inject(AuthService).checkAuthorization();

// Keep previous implementation, can be useful
// canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
//   if (!this.authService.isAuthenticated) {
//     if (state.url === RouteEnum.Home || state.url === `/${RouteEnum.Dashboard}`) {
//       this.router.navigate([RouteEnum.Auth]);
//     } else if (state.url === RouteEnum.Auth) {
//       return of(false);
//     } else {
//       return this.dialogInjectorService.openAuthDialog().pipe(map(res => !!res));
//     }
//     return of(null);
//   } else {
//     try {
//       return this.authService.fetchProfile().pipe(map(profile => !!profile));
//     } catch (e) {
//       // error fetch profile, return false
//       console.error(e);
//       return of(false);
//     }
//   }
// }
